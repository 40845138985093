import React, { useState, useRef, useEffect, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { useDispatch, useSelector } from "react-redux";
import BoLoader from "../../helpers/BoLoader";

import CheckButton from "react-validation/build/button";
import BoPaymentWrapper from "../BoPaymentLeftWrapper";
import useWindowDimensions from "../../helpers/WindowWidth";
import identityverificationObj from "../../services/identityverification.service";
import crossimg from "../../assets/images/icons/cross.svg";
import DialoguePopup from "../../helpers/DialoguePopup";
// import { DateRange, TodayRounded } from "@material-ui/icons";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import { subYears } from "date-fns";
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/jpg'];
const stateRequired = value => {
  if (!value) {
    document.getElementById("state").classList.add("has_error");
    return <span className="help-block">State is required!</span>;
  } else document.getElementById("state").classList.remove("has_error");
};
const idRequired = value => {
  if (!value) {
    document.getElementById("id_number").classList.add("has_error");
    return <span className="help-block">ID Number is required!</span>;
  } else document.getElementById("id_number").classList.remove("has_error");
};
const languageRequired = value => {
  if (!value) {
    document.getElementById("id_language").classList.add("has_error");
    return <span className="help-block">Language is required!</span>;
  } else document.getElementById("id_language").classList.remove("has_error");
};
const addressIssuedRequired = value => {
  if (!value) {
    document.getElementById("addressIssuedBy").classList.add("has_error");
    return <span className="help-block">Address Issued By is required!</span>;
  } else document.getElementById("addressIssuedBy").classList.remove("has_error");
};
const IdIssuedbyRequired = value => {
  if (!value) {
    document.getElementById("id_IssuedBy").classList.add("has_error");
    return <span className="help-block">ID Issued By is required!</span>;
  } else document.getElementById("id_IssuedBy").classList.remove("has_error");
};

const RegisterIssuerT = () => {
  const form = useRef();
  const { user: currentUser } = useSelector((state) => state.auth);
  const [UserId, setUserId] = useState(String(currentUser?.user?.UserId));

  const checkBtn = useRef();
  const [step, setStep] = useState(1);
  const [language, setLanguage] = useState('');
  const [boloader, setBoLoader] = useState(false);
  const [fileDetails, setFileDetails] = useState({
    selfie: { name: '', size: '' },
    drivingLicense: { name: '', size: '' },
    passport: { name: '', size: '' },
    proofOfAddress: { name: '', size: '' },
  });
  const [ImageFront, setImageFront] = useState();
  const [base64ImageFront, setbase64ImageFront] = useState("");
  const [ImageBack, setImageBack] = useState();
  const [kycId, setKycId] = useState();
  const [kycDocLength, setKycDocsLength] = useState(0);
  const [base64ImageBack, setbase64ImageBack] = useState("");
  const [ImageBackExt, setImageBackExt] = useState();
  const [ImageFrontExt, setImageFrontExt] = useState();
  const [isValidFileId, setisValidFileId] = useState(true);
  const [isValidFileSelfie, setisValidFileSelfie] = useState(true);
  const [isValidFileIdSize, setisValidFileIdSize] = useState(true);
  const [isValidFileSelfieSize, setisValidFileSelfieSize] = useState(true);
  const [isValidFileIdBack, setisValidFileIdBack] = useState(true);
  //Dialogue Popup
  const [modalShow, setModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [FilePathAddressProof, setFilePathAddressProof] = useState("");
  const [FilePathAddressProofBack, setFilePathAddressProofBack] = useState("");
  const [FilePathIdType, setFilePathIdType] = useState("");
  const [FilePathSelfieType, setFilePathSelfieType] = useState("");
  const [FilePathIdTypeBack, setFilePathIdTypeBack] = useState("");
  const [successfulSubmitted, setSuccessfulSubmitted] = useState(false);
  const [LogoNameID, setlogoNameID] = useState("");
  const [LogoNameIDBack, setlogoNameIDBack] = useState("");
  const [LogoNameSelfie, setlogoNameSelfie] = useState("");
  const [LogoNamePOF, setlogoNamePOF] = useState("");
  const [LogoNamePOFBack, setlogoNamePOFBack] = useState("");
  const [LogoSizeID, setlogoSizeID] = useState();
  const [LogoSizeSelfie, setlogoSizeSelfie] = useState();
  const [LogoSizeIDBack, setlogoSizeIDBack] = useState();
  const [LogoSizePOF, setlogoSizePOF] = useState();
  const [LogoSizePOFBack, setlogoSizePOFBack] = useState();
  const [isValidIdType, setiisValidIdType] = useState(true);
  const [isValidIdTypeBack, setiisValidIdTypeBack] = useState(true);
  const [isValidProofofAdrs, setiisValidProofofAdrs] = useState(true);
  const [isValidProofofAdrsBack, setiisValidProofofAdrsBack] = useState(true);
  const [isValidLogoIdType, setiisValidLogoIdType] = useState(true);
  const [isValidLogoSelfieType, setiisValidLogoSelfieType] = useState(true);
  const [isValidLogoIdTypeBack, setiisValidLogoIdTypeBack] = useState(true);
  const [isValidLogoProofofAdrs, setisValidLogoProofofAdrs] = useState(true);
  const [isValidLogoProofofAdrsBack, setisValidLogoProofofAdrsBack] = useState(true);
  const [State, seState] = useState();
  const [calledfrom, setcalledfrom] = useState();
  const [showIDIssueErr, setShowIDIssueErr] = useState("d-none");
  const [showIDExpiryErr, setshowIDExpiryErr] = useState("d-none");
  const [showAddressIssueErr, setShowAddressIssueErr] = useState("d-none");
  const POAAddress = useRef();
  const POAAddressBack = useRef();
  const [isValidFileIdBackSize, setisValidFileIdBackSize] = useState(true);
  const [isValidFilePoa, setiisValidFilePoa] = useState(true);
  const [isValidFilePoaSize, setiisValidFilePoaSize] = useState(true);
  const [isValidFilePoaBack, setiisValidFilePoaBack] = useState(true);
  const [isValidFilePoaBackSize, setiisValidFilePoaBackSize] = useState(true);
  const { height, width } = useWindowDimensions();
  const [Name, setBoUserName] = useState("");
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const [clientlogo, setclientlogo] = useState("");
  const [StateId, setStateID] = useState();
  const [AddressProof, setAddressProof] = useState();
  const [AddressProofBack, setAddressProofBack] = useState();
  const [IDType, setIDType] = useState(0);
  const [IdDocLang, setIdDocLang] = useState(0);
  const [AddressType, setAdressType] = useState(0);
  const [AddressDocLang, setAddressDocLang] = useState(0);
  const [IdNumber, setIdNumber] = useState();
  const [base64ID, setBase64ID] = useState("");
  const [base64Selfie, setBase64Selfie] = useState("");
  const [base64IDBack, setBase64IDBack] = useState("");
  const [base64Poa, setBase64Poa] = useState("");
  const [base64PoaBack, setBase64PoaBack] = useState("");
  const [idIssuedBy, setIdIssuedBy] = useState("");
  const [idIssueDate, setIdIssueDate] = useState("");
  const [idExpDate, setIdExpDate] = useState("");
  const [AddressIssuedBy, setAddressIssuedBy] = useState("");
  const [DocLanguage, setDocLanguage] = useState("");
  const [AddressIssueDate, setAddressIssueDate] = useState("");
  //Extra Dates For API's
  const [AddressIssueDateAPI, setAddressIssueDateAPI] = useState("");
  const [idIssueDateAPI, setIdIssueDateAPI] = useState("");
  const [idExpDateAPI, setIdExpDateAPI] = useState("");
  const [docType, setDocType] = useState();
  var dropDownIcon = {
    paddingLeft: "45px",
  };
  const requiredIssueDate = value => {
    if (!value) {
      document.getElementById("idIssueDate-frmgrp").classList.add("has_error");
      setShowIDIssueErr("");
      return false;
    } else {
      document.getElementById("idIssueDate-frmgrp").classList.remove("has_error");
      setShowIDIssueErr("d-none");
      return true;
    }
  };
  const requiredExpiryDate = value => {
    if (!value) {
      document.getElementById("idExpiryDate-frmgrp").classList.add("has_error");
      setshowIDExpiryErr("");
      return false;
    } else {
      document.getElementById("idExpiryDate-frmgrp").classList.remove("has_error");
      setshowIDExpiryErr("d-none");
      return true;
    }
  };
  const requiredIssueDateAddress = value => {
    if (!value) {
      document.getElementById("idIssueDateAddress-frmgrp").classList.add("has_error");
      setShowAddressIssueErr("");
      return false;
    } else {
      document.getElementById("idIssueDateAddress-frmgrp").classList.remove("has_error");
      setShowAddressIssueErr("d-none");
      return true;
    }
  };
  useEffect(() => {
    viewCardHolderT();
  }, [])
  const viewCardHolderT = () => {
    setBoLoader(true);
    identityverificationObj.viewcardholdert(UserId).then(
      (response) => {
        setBoLoader(false);
        setKycId(response?.data?.payload?.item1?.kycDocs[0]?.kycDocID);
        setKycDocsLength(response?.data?.payload?.item1?.kycDocs?.length);
        //for testing remove later and uncomment the above line
         //setKycDocsLength(2);
      },
      (error) => {
        setBoLoader(false);
      }
    );
  }
  useEffect(() => {
    setiisValidIdType(true);
  }, [IDType]);

  useEffect(() => {
    setiisValidProofofAdrs(true);
  }, [AddressProof]);
  useEffect(() => {
    setiisValidProofofAdrsBack(true);
  }, [AddressProofBack]);
  useEffect(() => {
    setiisValidLogoIdType(true);
  }, [LogoNameID]);
  useEffect(() => {
    setiisValidLogoIdTypeBack(true);
  }, [LogoNameIDBack]);
  useEffect(() => {
    setisValidLogoProofofAdrs(true);
  }, [LogoNamePOF]);
  useEffect(() => {
    setisValidLogoProofofAdrsBack(true);
  }, [LogoNamePOFBack]);
  useEffect(() => {
    setisValidFileId(true);
  }, [setlogoSizeID]);
  useEffect(() => {
    setisValidFileIdSize(true);
  }, [setlogoSizeID]);
  useEffect(() => {
    setisValidFileIdBack(true);
  }, [setlogoSizeIDBack]);
  useEffect(() => {
    setiisValidFilePoaBack(true);
  }, [setlogoSizePOFBack]);
  const changeHandlerDocType = (e) => {
    const status = e.target.selectedOptions[0].value;
    let statusvalue = parseInt(status);
    setDocType(statusvalue);
  };
  const changeHandlerIdDocLang = (e) => {
    const status = e.target.selectedOptions[0].value;
    let statusvalue = String(status);
    setIdDocLang(statusvalue);
  };
  const changeHandlerAddressType = (e) => {
    const status = e.target.selectedOptions[0].value;
    let statusvalue = parseInt(status);
    setAdressType(statusvalue);
  };
  const changeHandlerAddressLang = (e) => {
    const status = e.target.selectedOptions[0].value;
    let statusvalue = parseInt(status);
    setAddressDocLang(statusvalue);
  };
  function getBase64(file, callback) {

    const reader = new FileReader();

    reader.addEventListener('load', () => callback(reader.result));

    reader.readAsDataURL(file);
  }
  const onChangeIDtype = (e) => {
    const file_data = e.target.files[0];
    console.log(file_data)
    const formdata = new FormData();
    let reg = /(.*?)\.(jpg|bmp|jpeg|PNG|png)$/;
    if (!file_data.name.match(reg)) {
      setisValidFileId(false);
      return false;
    }
    else if (file_data.size > 1030000) {
      setisValidFileIdSize(false);
      return false;
    }
    setlogoNameID(file_data.name);
    setImageFrontExt(file_data?.type);
    var size = formatBytes(file_data.size);
    setlogoSizeID(size);
    formdata.append("File", file_data);
    setBoLoader(true);
    identityverificationObj.uploaduserdocs(formdata).then((response) => {
      setBoLoader(false);
      if (response) {
        setFilePathIdType(response.data.payload);
        getBase64(file_data, function (base64Data) {
          setBase64ID(base64Data)
        });
      }
    }).catch(()=>{
      setBoLoader(false);
    });
  };
  const onChangeSelfietype = (e) => {
    const file_data = e.target.files[0];
    console.log(file_data)
    const formdata = new FormData();
    let reg = /(.*?)\.(jpg|bmp|jpeg|PNG|png)$/;
    if (!file_data.name.match(reg)) {
      setisValidFileSelfie(false);
      return false;
    }
    else if (file_data.size > 1030000) {
      setisValidFileSelfieSize(false);
      return false;
    }
    setlogoNameSelfie(file_data.name);
    var size = formatBytes(file_data.size);
    setlogoSizeSelfie(size);
    formdata.append("File", file_data);
    identityverificationObj.uploaduserdocs(formdata).then((response) => {
      if (response) {
        setFilePathSelfieType(response.data.payload);
        getBase64(file_data, function (base64Data) {
          setBase64Selfie(base64Data)
        });
      }
    });
  };
  const onChangeIDtypeBack = (e) => {
    const file_data = e.target.files[0];
    const formdata = new FormData();
    let reg = /(.*?)\.(jpg|bmp|jpeg|PNG|png)$/;
    if (!file_data.name.match(reg)) {
      setisValidFileIdBack(false);
      return false;
    }
    else if (file_data.size > 1030000) {
      setisValidFileIdSize(false);
      return false;
    }
    setlogoNameIDBack(file_data.name);
    setImageBackExt(file_data?.type);
    var size = formatBytes(file_data.size);
    setlogoSizeIDBack(size);
    formdata.append("File", file_data);
    setBoLoader(true);
    identityverificationObj.uploaduserdocs(formdata).then((response) => {
      if (response) {
        setBoLoader(false);
        setFilePathIdTypeBack(response.data.payload);
        getBase64(file_data, function (base64Data) {
          setBase64IDBack(base64Data)
        });
      }
    }).catch(()=>{
      setBoLoader(false);
    });
  };
  const onChangeProofofaddress = (e) => {
    const file_data = e.target.files[0];
    const formdata = new FormData();
    let reg = /(.*?)\.(jpg|bmp|jpeg|PNG|png)$/;
    if (!file_data.name.match(reg)) {
      setiisValidFilePoa(false);
      return false;
    }
    else if (file_data.size > 1030000) {
      setisValidFileIdSize(false);
      return false;
    }
    setlogoNamePOF(file_data.name);
    setImageFrontExt(file_data?.type);
    var size = formatBytes(file_data.size);
    setlogoSizePOF(size);
    formdata.append("File", file_data);
    setBoLoader(true);
    identityverificationObj.uploaduserdocs(formdata).then((response) => {
      setBoLoader(false);
      if (response) {
        setFilePathAddressProof(response.data.payload);
        getBase64(file_data, function (base64Data) {
          setBase64Poa(base64Data)
        });
      }
    }).catch(()=>{
      setBoLoader(false);
    });
  };
  const onChangeProofofaddressBack = (e) => {
    const file_data = e.target.files[0];
    const formdata = new FormData();
    let reg = /(.*?)\.(jpg|bmp|jpeg|PNG|png)$/;
    if (!file_data.name.match(reg)) {
      setiisValidFilePoaBack(false);
      return false;
    }
    else if (file_data.size > 1030000) {
      setisValidFileIdSize(false);
      return false;
    }
    setlogoNamePOFBack(file_data.name);
    setImageBackExt(file_data?.type);
    var size = formatBytes(file_data.size);
    setlogoSizePOFBack(size);
    formdata.append("File", file_data);
    setBoLoader(true);
    identityverificationObj.uploaduserdocs(formdata).then((response) => {
      setBoLoader(false);
      if (response) {
        setFilePathAddressProofBack(response.data.payload);
        getBase64(file_data, function (base64Data) {
          setBase64PoaBack(base64Data)
        });
      }
    }).catch(()=>{
      setBoLoader(false);
    });
  };
  const onCrossImg = () => {
    setlogoNamePOF("");
    setisValidLogoProofofAdrs(true);
    setiisValidFilePoa(true);
    setisValidFileIdSize(true);
  };
  const onCrossImgIdBack = () => {
    // setlogoNamePOFBack("");
    setlogoNameIDBack("");
    setisValidLogoProofofAdrsBack(true);
    setiisValidFilePoaBack(true);
  };
  const onCrossImgId = () => {
    setlogoNameID("");
    setiisValidLogoIdType(true);
    setisValidFileId(true);
    setisValidFileIdSize(true);
  };
  const onCrossImgSelfie = () => {
    setlogoNameSelfie("");
    setiisValidLogoSelfieType(true);
    setisValidFileSelfie(true);
    setisValidFileSelfieSize(true);
  };
  const onCrossImgBack = () => {
    //setlogoNameIDBack("");
    setlogoNamePOFBack("");
    setiisValidLogoIdTypeBack(true);
    setisValidFileIdBack(true);
    setisValidFileIdSize(true);
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const onChangeNumber = (e) => {
    const name = e.target.value;
    setIdNumber(name);
  };
  const onChangeState = e => {
    let value = e.target.value;
    setStateID(value);
  };
  const validationofForm = () => {
    let id = false;
    let idback = false;
    let poa = false;
    let poaback = false;
    if (FilePathIdType === null || FilePathIdType === undefined || FilePathIdType === "") {
      setiisValidLogoIdType(false);
      id = false;
    } else { setiisValidLogoIdType(true); id = true };
    if (FilePathIdTypeBack === null || FilePathIdTypeBack === undefined || FilePathIdTypeBack === "") {
      setiisValidLogoIdTypeBack(false);
      idback = false;
    } else { setiisValidLogoIdType(true); idback = true };
    if (FilePathAddressProof === null || FilePathAddressProof === undefined || FilePathAddressProof === "") {
      setisValidLogoProofofAdrs(false);
      poa = false;
    } else { setisValidLogoProofofAdrs(true); poa = true };
    if (FilePathAddressProofBack === null || FilePathAddressProofBack === undefined || FilePathAddressProofBack === "") {
      setisValidLogoProofofAdrsBack(false);
      poaback = false;
    } else { setisValidLogoProofofAdrsBack(true); poaback = true };
    if (id === true && idback === true && poa === true && poaback === true)
      return true;
    else
      return false;
  }
  const saveKycDetails = (e) => {
    e.preventDefault();
    // let chckissueDateId = requiredIssueDate(idIssueDate);
    // let chckexpiryDateId = requiredExpiryDate(idExpDate);
    // let chckissueDateExpiry = requiredIssueDateAddress(AddressIssueDate);
    // let validdocuments = validationofForm();
    // if ((checkBtn.current.context._errors.length > 0 || validdocuments === false) ||
    // !chckissueDateId || !chckexpiryDateId || !chckissueDateExpiry) {
    //     return;
    // }
    let base64IDval = base64ID.split(",");
    let base64IDBackval = base64IDBack.split(",");
    let base64Poaval = base64Poa.split(",");
    let base64PoaBackval = base64PoaBack.split(",");

    setBoLoader(true);
    identityverificationObj
      .IssuerGKyc(
        IdNumber,
        IDType,
        IdDocLang,
        AddressType,
        AddressDocLang,
        FilePathIdType,
        FilePathIdTypeBack,
        FilePathAddressProof,
        FilePathAddressProofBack,
        UserId,
        base64IDval[1],
        base64IDBackval[1],
        base64Poaval[1],
        base64PoaBackval[1],
        StateId,
        idIssuedBy,
        idIssueDateAPI,
        idExpDateAPI,
        AddressIssuedBy,
        AddressIssueDateAPI
      )
      .then((response) => {
        setBoLoader(false);
        if (response) {
          setSuccessfulSubmitted(true);
          setAddressProof();
          setIDType();
        }
      })
      .catch((err) => {
        setBoLoader(false);
        let msgBody = err?.response?.data?.message
          ? err.response.data.message : "Something Went Wrong" + "-" + "Please Contact support for assistance"

        let errIcon = "icon-error";
        errorAndSuccessModl("Error", msgBody, errIcon);
      });
  };
  const onChangeIdIssue = value => {
    let date = value;
    let apidate = value;
    if (date) {
      date = new Date(date);
      apidate = date?.toJSON()?.split("T")[0];
    }
    setIdIssueDateAPI(apidate);
    setIdIssueDate(date);
    requiredIssueDate(value);
  };
  const onChangeIdExpiry = value => {
    let date = value;
    let apidate = value;
    if (date) {
      date = new Date(date);
      apidate = date?.toJSON()?.split("T")[0];
    }
    setIdExpDateAPI(apidate);
    setIdExpDate(date);
    requiredExpiryDate(value);
  };
  const onChangeAddressIssue = value => {
    let date = value;
    let apidate = value;
    if (date) {
      date = new Date(date);
      apidate = date?.toJSON()?.split("T")[0];
    }
    setAddressIssueDateAPI(apidate);
    setAddressIssueDate(date);
    requiredIssueDateAddress(value);
  };
  const onChangeIdIssuedBy = (e) => {
    const name = e.target.value;
    setIdIssuedBy(name);
  };
  const onChangeAddressIssuedBy = (e) => {
    const name = e.target.value;
    setAddressIssuedBy(name);
  };
  const onChangeLanguage = (e) => {
    const name = e.target.value;
    setLanguage(name);
  };
  const validateFile = (file) => {
    if (!file) return 'File is required';
    if (!ACCEPTED_FILE_TYPES.includes(file.type)) return 'Only JPEG files are accepted';
    if (file.size > MAX_FILE_SIZE) return 'File size must be less than 5MB';
    return true;
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
 

  function getBase64(file, callback) {

    const reader = new FileReader();

    reader.addEventListener('load', () => callback(reader.result));

    reader.readAsDataURL(file);
  }
  const submitSelfie = () => {
    let userId = UserId,
      IssuedBy = "",
      IssueDate = "",
      ExpireDate = "",
      imageFrontExt = 0,
      imgback = "",
      ImageBackExt = 0,
      ImageBackUrl = ""
    if (ImageFrontExt.includes("jpeg"))
      imageFrontExt = 1;
    else if (ImageFrontExt.includes("png"))
      imageFrontExt = 0;
    else if (ImageFrontExt.includes("pdf"))
      imageFrontExt = 2;
      let base64IDval = base64ID.split(",");
      setBoLoader(true);
      identityverificationObj
        .issuertkyc(
          userId,
          0,
          language,//document language
          "", //document number
          IssuedBy,
          IssueDate,
          ExpireDate,
          base64IDval[1],// ID Front base 64
          imageFrontExt,
          imgback,
          ImageBackExt,
          FilePathIdType, // blob link ID Front 
          ImageBackUrl
        )
        .then((response) => {
          setBoLoader(false);
          if (response) {
            viewCardHolderT();
            clearAllFields();
            //setKycDocsLength(1);
          }
        })
        .catch((err) => {
          setBoLoader(false);
          let msgBody = err?.response?.data?.message
            ? err.response.data.message : "Something Went Wrong" + "-" + "Please Contact support for assistance"

          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
        });
    


  };
  const submitProofOfId = () => {
    let userId = UserId,
      ExpireDate = "",
      imageFrontExt = 0,
      imageBackExt = 0
    if (ImageFrontExt.includes("jpeg"))
      imageFrontExt = 1;
    else if (ImageFrontExt.includes("png"))
      imageFrontExt = 0;
    else if (ImageFrontExt.includes("pdf"))
      imageFrontExt = 2;
    if (ImageBackExt.includes("jpeg"))
      imageBackExt = 1;
    else if (ImageFrontExt.includes("png"))
      imageBackExt = 0;
    else if (ImageFrontExt.includes("pdf"))
      imageBackExt = 2;
    let base64IDval = base64ID.split(",");
    let base64IDBackval = base64IDBack.split(",");
    setBoLoader(true);
    identityverificationObj
      .issuertkyc(
        userId, //User Id of cardholder
        docType,//document type
        language,//document language
        IdNumber,//document number
        idIssuedBy,//document issued by
        idIssueDate, //document issuedate
        ExpireDate, // document Expiry Date
        base64IDval[1],// ID Front base 64
        imageFrontExt, //ID Front Extension 
        base64IDBackval[1], // ID Back base 64
        imageBackExt, //ID back extension
        FilePathIdType, // blob link ID Front 
        FilePathIdTypeBack, //blob link ID Back
      )
      .then((response) => {
        setBoLoader(false);
        if (response) {
          viewCardHolderT();
          clearAllFields();
          //setKycDocsLength(2);
        }
      })
      .catch((err) => {
        setBoLoader(false);
        let msgBody = err?.response?.data?.message
          ? err.response.data.message : "Something Went Wrong" + "-" + "Please Contact support for assistance"

        let errIcon = "icon-error";
        errorAndSuccessModl("Error", msgBody, errIcon);
      });



  };
  const submitProofOfAddress = () => {
    let userId = UserId,
      ExpireDate = "",
      imageFrontExt = 0,
      imageBackExt = 0
    if (ImageFrontExt?.includes("jpeg"))
      imageFrontExt = 1;
    else if (ImageFrontExt?.includes("png"))
      imageFrontExt = 0;
    else if (ImageFrontExt?.includes("pdf"))
      imageFrontExt = 2;
    if (ImageBackExt?.includes("jpeg"))
      imageBackExt = 1;
    else if (ImageFrontExt?.includes("png"))
      imageBackExt = 0;
    else if (ImageFrontExt?.includes("pdf"))
      imageBackExt = 2;
    let base64Poaval = base64Poa.split(",");
    let base64PoaBackval = base64PoaBack.split(",");
    setBoLoader(true);
    identityverificationObj
      .issuertkyc(
        userId,  //User Id of cardholder
        docType,//document type
        language,//Address document language
        IdNumber,//Address document number
        idIssuedBy,//document issued by
        idIssueDate, //document issuedate
        ExpireDate, // document Expiry Date
        base64Poaval[1],// Address Front base 64
        imageFrontExt,//Address Front Extension
        base64PoaBackval[1],//Address back base 64
        imageBackExt, //ID back extension
        FilePathAddressProof, // blob link Address Front 
        FilePathAddressProofBack, // blob link Address Back
      )
      .then((response) => {
        setBoLoader(false);
        if (response) {
          viewCardHolderT();
          clearAllFields();
          //setKycDocsLength(3);
        }
      })
      .catch((err) => {
        setBoLoader(false);
        let msgBody = err?.response?.data?.message
          ? err.response.data.message : "Something Went Wrong" + "-" + "Please Contact support for assistance"

        let errIcon = "icon-error";
        errorAndSuccessModl("Error", msgBody, errIcon);
      });



  };
  
  //don'nt delete this we will use this for deleting the kyc.
  const removeDocument = () => {
    identityverificationObj
      .deleteKyct(UserId, kycId).then((response) => {

      }).catch((err) => {

      })
  }
  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  

  const submitkyctoissuert = () => {
    identityverificationObj
      .submitkyctoissuert(UserId, kycId).then((response) => {
        setSuccessfulSubmitted(true);
      }).catch((err) => {
        setBoLoader(false);
        let msgBody = err?.response?.data?.message
          ? err.response.data.message : "Something Went Wrong" + "-" + "Please Contact support for assistance"

        let errIcon = "icon-error";
        errorAndSuccessModl("Error", msgBody, errIcon);
      })
  }
  const clearAllFields=()=>{
    setFilePathIdType("");
    setFilePathIdTypeBack("");
    setFilePathAddressProof("");
    setFilePathAddressProofBack("");
    setLanguage("");
    setlogoNameID("");
    setlogoNameIDBack("");
    setlogoNamePOF("");
    setlogoNamePOFBack("");
    setBase64Poa("")
    setBase64PoaBack("");
    setBase64ID("");
    setBase64IDBack("");
    setIdExpDate("");
    setIdNumber();
    setIdIssueDate("");
    setIdIssuedBy("");
  }
  return (
    <div className="row">
      <BoLoader loading={boloader} />
      {width > 767 && <BoPaymentWrapper
        pagename={["Upload Your", <br />, "Kyc"]}
        pagemsg={"KYC Documents"}
        clientlogo={clientlogo}
      />}
      <div className="col-md-7 col-lg-8 onbo-content onbo-content-mbl">
        <div className="row">
          {(!currentUser?.user?.IsKycSubmitted && !boloader) && (<>
            {width <= 767 && <div className="col-12">
              <div className="bo-thumb"></div>
              <h2>Create Account</h2>
            </div>}
            {/* <div className="col-xl-6 mt-4">
                <div className="form-group submit-field">
                  <a className="btn btn-large w-100" onClick={removeDocument}>Delete</a>
                </div>
              </div> */}
            <div className="bo-on-screens position-relative">
              <div className="col-sm-12 col-xl-10">
                <div className="col-md-12 mb-24">
                  <h3>Please Complete The KYC.</h3>
                </div>
                <div className="form-container">
                 
                  {
                    kycDocLength == 0 && (
                      <>
                        <h2>Step 1: Upload Selfie</h2>
                        <div className="bo-on-screens position-relative">
                          <div className="col-sm-12 col-xl-10">
                            <div className="col-sm-12 col-md-10">
                              <Form onSubmit={(e) => e.preventDefault()} ref={form}>
                                <div className="row">
                                  <div className="col-lg-6" id="id_language">
                                    <div className="form-group">
                                      <label htmlFor="cardholderName">Enter Language(2 Digit ISO)*</label>
                                      <div className="form-controls">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="cardholderName"
                                          value={language}
                                          onChange={onChangeLanguage}
                                          validations={[languageRequired]}
                                          maxLength="2"

                                        />
                                        <span className="icon-user _ico"></span>
                                      </div>
                                    </div>
                                  </div>
                                  {LogoNameID === "" ? (
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label
                                          htmlFor="email"
                                          className="d-xs-none d-md-inline-block"
                                        >
                                          ID Front*  &nbsp;&nbsp;
                                        </label>
                                        <div className="form-controls">
                                          <div className="bo-file-control">
                                            <input
                                              type="file"
                                              hidden
                                              id="idtype"
                                              className="form-control"
                                              name="logo"
                                              onChange={onChangeIDtype}
                                            />
                                            <input
                                              className="form-control file-control-input"
                                              type="file"
                                              readOnly
                                              onChange={onChangeIDtype}
                                            />
                                            <h5 className="form-control file-control file-control-text">
                                              <label>Drag files here to upload or </label>{" "}
                                              Browse file
                                            </h5>

                                          </div>
                                          <span className="icon-upload-file _ico">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                          </span>
                                        </div>
                                        <span className="fs-12 clr-submarine mt-issuert d-block">
                                          Supported file types jpg,jpeg
                                          transparent background and max size 1 MB
                                        </span>
                                        {!isValidFileIdSize && (
                                          <span className="help-block has_error">
                                            Max Upload Size is 1MB&nbsp;
                                          </span>
                                        )}
                                        {!isValidFileId && (
                                          <span className="help-block has_error">
                                            Invalid File Format! &nbsp;
                                          </span>
                                        )}
                                        {!isValidLogoIdType && (
                                          <span className="help-block has_error">
                                            ID Front is required!&nbsp;
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <div className="bo-file d-flex">
                                          <div className="file-name">
                                            <span className="icon-upload-file _ico">
                                              <span className="path1"></span>
                                              <span className="path2"></span>
                                            </span>
                                            <h5>
                                              <span>{LogoNameID}</span>
                                            </h5>
                                          </div>
                                          <div
                                            className="ml-auto file-size"
                                            onClick={onCrossImgId}
                                          >
                                            <span>{LogoSizeID}</span>
                                            <img
                                              className="cross-icon"
                                              src={crossimg}
                                              onClick={onCrossImgId}
                                              alt=""
                                            />

                                          </div>

                                        </div>
                                      </div>
                                      {!isValidLogoIdType && (
                                        <span className="help-block has_error">
                                          Valid ID Type is required!
                                        </span>
                                      )}
                                    </div>
                                  )}
                                  <div className="col-xl-6 mt-4">
                                    <div className="form-group submit-field">
                                      <a className="btn btn-large w-100" onClick={submitSelfie}>Next</a>
                                    </div>
                                  </div>
                                  <div className="col-xl-6 mt-4">
                                    <div className="form-group submit-field">
                                      <a
                                        className="btn btn-large w-100"
                                        onClick={() => { sessionStorage?.removeItem("user"); window.location.reload(); }}
                                      >
                                        Logout
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }
                  {
                    kycDocLength == 1 && (
                      <>
                        <h2>Step 2: Proof of Id</h2>
                        <div className="bo-on-screens position-relative">
                          <div className="col-sm-12 col-xl-10">
                            <div className="col-sm-12 col-md-10">
                              <Form onSubmit={(e) => e.preventDefault()} ref={form}>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <label>Select ID Type*</label>
                                      <div className="form-controls">
                                        <select
                                          onChange={changeHandlerDocType}
                                          className="form-control no-icon"
                                          style={dropDownIcon}
                                        >
                                          <option value={1}>
                                            Passport
                                          </option>
                                          <option value={2}>
                                            Driver License
                                          </option>
                                          <option value={3}>
                                            National Id
                                          </option>
                                        </select>
                                        <span className="icon-card-id _ico"></span>
                                        {!isValidIdType && (
                                          <span className="help-block has_error">
                                            ID Type is required!
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6" id="id_language">
                                    <div className="form-group">
                                      <label htmlFor="cardholderName">Enter Language(2 Digit ISO)*</label>
                                      <div className="form-controls">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="cardholderName"
                                          value={language}
                                          onChange={onChangeLanguage}
                                          validations={[languageRequired]}
                                          maxLength="2"

                                        />
                                        <span className="icon-user _ico"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6" id="id_number">
                                    <div className="form-group">
                                      <label htmlFor="cardholderName">Doc Number*</label>
                                      <div className="form-controls">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="id_number"
                                          value={IdNumber}
                                          onChange={onChangeNumber}
                                          validations={[idRequired]}
                                          maxLength="30"

                                        />
                                        <span className="icon-user _ico"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6" id="id_IssuedBy">
                                    <div className="form-group">
                                      <label htmlFor="cardholderName">ID Issued By(2 Digit ISO Code)*</label>
                                      <div className="form-controls">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="id_IssuedBy"
                                          value={idIssuedBy}
                                          onChange={onChangeIdIssuedBy}
                                          validations={[IdIssuedbyRequired]}
                                          maxLength="10"

                                        />
                                        <span className="icon-user _ico"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group mb-3" id="idIssueDate-frmgrp">
                                      <label>ID Issue Date* </label>
                                      <div className="form-controls">
                                        <ReactDatePickerHelper
                                          datevalue={idIssueDate}
                                          onchangedate={onChangeIdIssue}
                                       
                                        />
                                        <span className="icon-calender _ico"></span>
                                        <span className="icon-round icon-calender-mini"></span>

                                        <span className={"help-block " + showIDIssueErr}>
                                          ID Issue Date is required!
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group mb-3" id="idExpiryDate-frmgrp">
                                      <label>ID Issue Expiry* </label>
                                      <div className="form-controls">
                                        <ReactDatePickerHelper
                                          datevalue={idExpDate}
                                          onchangedate={onChangeIdExpiry}
                                      
                                        />
                                        <span className="icon-calender _ico"></span>
                                        <span className="icon-round icon-calender-mini"></span>
                                        <span className={"help-block " + showIDExpiryErr}>
                                          ID Issue Expiry is required!
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  {LogoNameID === "" ? (
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label
                                          htmlFor="email"
                                          className="d-xs-none d-md-inline-block"
                                        >
                                          ID Front*  &nbsp;&nbsp;
                                        </label>
                                        <div className="form-controls">
                                          <div className="bo-file-control">
                                            <input
                                              type="file"
                                              hidden
                                              id="idtype"
                                              className="form-control"
                                              name="logo"
                                              onChange={onChangeIDtype}
                                            />
                                            <input
                                              className="form-control file-control-input"
                                              type="file"
                                              readOnly
                                              onChange={onChangeIDtype}
                                            />
                                            <h5 className="form-control file-control file-control-text">
                                              <label>Drag files here to upload or </label>{" "}
                                              Browse file
                                            </h5>

                                          </div>
                                          <span className="icon-upload-file _ico">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                          </span>
                                        </div>
                                        <span className="fs-12 clr-submarine mt-issuert d-block">
                                          Supported file types jpg,jpeg
                                          transparent background and max size 1 MB
                                        </span>
                                        {!isValidFileIdSize && (
                                          <span className="help-block has_error">
                                            Max Upload Size is 1MB&nbsp;
                                          </span>
                                        )}
                                        {!isValidFileId && (
                                          <span className="help-block has_error">
                                            Invalid File Format! &nbsp;
                                          </span>
                                        )}
                                        {!isValidLogoIdType && (
                                          <span className="help-block has_error">
                                            ID Front is required!&nbsp;
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <div className="bo-file d-flex">
                                          <div className="file-name">
                                            <span className="icon-upload-file _ico">
                                              <span className="path1"></span>
                                              <span className="path2"></span>
                                            </span>
                                            <h5>
                                              <span>{LogoNameID}</span>
                                            </h5>
                                          </div>
                                          <div
                                            className="ml-auto file-size"
                                            onClick={onCrossImgId}
                                          >
                                            <span>{LogoSizeID}</span>
                                            <img
                                              className="cross-icon"
                                              src={crossimg}
                                              onClick={onCrossImgId}
                                              alt=""
                                            />

                                          </div>

                                        </div>
                                      </div>
                                      {!isValidLogoIdType && (
                                        <span className="help-block has_error">
                                          Valid ID Type is required!
                                        </span>
                                      )}
                                    </div>
                                  )}
                                  {LogoNameIDBack === "" ? (
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label
                                          htmlFor="emailback"
                                          className="d-xs-none d-md-inline-block"
                                        >
                                          ID Back*&nbsp;&nbsp;
                                        </label>
                                        <div className="form-controls">
                                          <div className="bo-file-control">
                                            <input
                                              type="file"
                                              hidden
                                              id="idtype"
                                              className="form-control"
                                              name="logo"
                                              onChange={onChangeIDtypeBack}
                                            />
                                            <input
                                              className="form-control file-control-input"
                                              type="file"
                                              readOnly
                                              onChange={onChangeIDtypeBack}
                                            />
                                            <h5 className="form-control file-control file-control-text">
                                              <label>Drag files here to upload or </label>{" "}
                                              Browse file
                                            </h5>

                                          </div>
                                          <span className="icon-upload-file _ico">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                          </span>
                                        </div>
                                        <span className="fs-12 clr-submarine mt-issuert d-block">
                                          Supported file types jpg,jpeg
                                          transparent background and max size 1MB
                                        </span>
                                        {!isValidFileIdBack && (
                                          <span className="help-block has_error">
                                            Invalid file format
                                          </span>
                                        )}
                                        {!isValidFileIdSize && (
                                          <span className="help-block has_error">
                                            Max Upload Size is 1MB
                                          </span>
                                        )}
                                        {!isValidLogoIdTypeBack && (
                                          <span className="help-block has_error">
                                            ID Back is required!
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <div className="bo-file d-flex">
                                          <div className="file-name">
                                            <span className="icon-upload-file _ico">
                                              <span className="path1"></span>
                                              <span className="path2"></span>
                                            </span>
                                            <h5>
                                              <span>{LogoNameIDBack}</span>
                                            </h5>
                                          </div>
                                          <div
                                            className="ml-auto file-size"
                                            onClick={onCrossImgIdBack}
                                          >
                                            <span>{LogoSizeIDBack}</span>
                                            <img
                                              className="cross-icon"
                                              src={crossimg}
                                              onClick={onCrossImgIdBack}
                                              alt=""
                                            />

                                          </div>

                                        </div>
                                      </div>
                                      {!isValidLogoIdTypeBack && (
                                        <span className="help-block has_error">
                                          Valid ID Type is required!
                                        </span>
                                      )}
                                    </div>
                                  )}
                                  <div className="col-xl-6 mt-4">
                                    <div className="form-group submit-field">
                                      <a className="btn btn-large w-100" onClick={submitProofOfId}>Next</a>
                                    </div>
                                  </div>
                                  <div className="col-xl-6 mt-4">
                                    <div className="form-group submit-field">
                                      <a
                                        className="btn btn-large w-100"
                                        onClick={() => { sessionStorage.removeItem("user"); window.location.reload(); }}
                                      >
                                        Logout
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }
                  {
                    kycDocLength == 2 && (
                      <>
                        <h2>Step 3: Proof of address</h2>
                        <div className="bo-on-screens position-relative">
                          <div className="col-sm-12 col-xl-10">
                            <div className="col-sm-12 col-md-10">
                              <Form onSubmit={(e) => e.preventDefault()} ref={form}>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <label>Select Address Type*</label>
                                      <div className="form-controls">
                                        <select
                                          onChange={changeHandlerDocType}
                                          className="form-control no-icon"
                                          style={dropDownIcon}
                                        >
                                          <option value={5}>
                                            Credit Card Statement
                                          </option>
                                          <option value={6}>
                                            Utility Bill
                                          </option>
                                          <option value={7}>
                                            Bank Statement
                                          </option>
                                          <option value={8}>
                                            Bank Letter
                                          </option>

                                        </select>
                                        <span className="icon-card-id _ico"></span>
                                        {!isValidIdType && (
                                          <span className="help-block has_error">
                                            Address Type is required!
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-6" id="addressIssuedBy">
                                    <div className="form-group">
                                      <label htmlFor="cardholderName">ISO Doc Language *</label>
                                      <div className="form-controls">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="cardholderName"
                                          value={language}
                                          onChange={onChangeLanguage}
                                          validations={[addressIssuedRequired]}
                                          maxLength="20"
                                        />
                                        <span className="icon-user _ico"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6" id="addressIssuedBy">
                                    <div className="form-group">
                                      <label htmlFor="cardholderName">Address Issued By(2 Digit ISO Code)*</label>
                                      <div className="form-controls">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="cardholderName"
                                          value={AddressIssuedBy}
                                          onChange={onChangeAddressIssuedBy}
                                          validations={[addressIssuedRequired]}
                                          maxLength="20"
                                        />
                                        <span className="icon-user _ico"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group mb-3" id="idIssueDateAddress-frmgrp">
                                      <label>Address Issue Date* </label>
                                      <div className="form-controls">
                                        <ReactDatePickerHelper
                                          datevalue={AddressIssueDate}
                                          onchangedate={onChangeAddressIssue}
                                        />
                                        <span className="icon-calender _ico"></span>
                                        <span className="icon-round icon-calender-mini"></span>
                                        <span className={"help-block " + showAddressIssueErr}>
                                          Address Issue Date is required!
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {LogoNamePOF === "" ? (
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label
                                          htmlFor="email"
                                          className="d-xs-none d-md-inline-block"
                                        >
                                          Address Front* &nbsp;&nbsp;
                                        </label>
                                        <div className="form-controls">
                                          <div className="bo-file-control">
                                            <input
                                              type="file"
                                              id="proofofaddress"
                                              hidden
                                              className="form-control file-control"
                                              placeholder="Drag files here to upload or"
                                              name="logo"
                                              onChange={onChangeProofofaddress}
                                              ref={POAAddress}
                                            />
                                            <input
                                              className="form-control file-control-input"
                                              type="file"
                                              readOnly
                                              onChange={onChangeProofofaddress}
                                              ref={POAAddress}
                                            />
                                            <h5 className="form-control file-control file-control-text">
                                              <label>Drag files here to upload or </label>{" "}
                                              Browse file
                                            </h5>

                                          </div>
                                          <span className="icon-upload-file _ico">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                          </span>
                                        </div>
                                        <span className="fs-12 clr-submarine mt-issuert d-block">
                                          Supported file types jpg,jpeg with
                                          transparent background and max size 1 MB
                                        </span>
                                        {!isValidFilePoa && (
                                          <span className="help-block has_error">
                                            Invalid file format&nbsp;
                                          </span>
                                        )}
                                        {!isValidFileIdSize && (
                                          <span className="help-block has_error">
                                            Max Upload Size is 1MB&nbsp;
                                          </span>
                                        )}
                                        {!isValidLogoProofofAdrs && (
                                          <span className="help-block has_error">
                                            Address Front is required!&nbsp;
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <div className="bo-file d-flex">
                                          <div className="file-name">
                                            <span className="icon-upload-file _ico">
                                              <span className="path1"></span>
                                              <span className="path2"></span>
                                            </span>
                                            <h5>
                                              <span>{LogoNamePOF}</span>
                                            </h5>
                                          </div>
                                          <div
                                            className="ml-auto file-size"
                                            onClick={onCrossImg}
                                          >
                                            <span>{LogoSizePOF}</span>
                                            <img
                                              className="cross-icon"
                                              src={crossimg}
                                              onClick={onCrossImg}
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {!isValidLogoProofofAdrs && (
                                        <span className="help-block has_error">
                                          Valid Proof of address is required!
                                        </span>
                                      )}
                                    </div>
                                  )}
                                  {LogoNamePOFBack === "" ? (
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label
                                          htmlFor="email"
                                          className="d-xs-none d-md-inline-block"
                                        >
                                          Address Back* &nbsp;&nbsp;
                                        </label>
                                        <div className="form-controls">
                                          <div className="bo-file-control">
                                            <input
                                              type="file"
                                              id="proofofaddress"
                                              hidden
                                              className="form-control file-control"
                                              placeholder="Drag files here to upload or"
                                              name="logo"
                                              onChange={onChangeProofofaddressBack}
                                            />
                                            <input
                                              className="form-control file-control-input"
                                              type="file"
                                              readOnly
                                              onChange={onChangeProofofaddressBack}
                                            />
                                            <h5 className="form-control file-control file-control-text">
                                              <label>Drag files here to upload or </label>{" "}
                                              Browse file
                                            </h5>

                                          </div>
                                          <span className="icon-upload-file _ico">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                          </span>
                                        </div>
                                        <span className="fs-12 clr-submarine mt-issuert d-block">
                                          Supported file types jpg,jpeg
                                          transparent background and max size 1 MB
                                        </span>
                                        {!isValidFilePoaBack && (
                                          <span className="help-block has_error">
                                            Invalid file format&nbsp;
                                          </span>
                                        )}
                                        {!isValidFileIdSize && (
                                          <span className="help-block has_error">
                                            Max Upload Size is 1MB&nbsp;
                                          </span>
                                        )}
                                        {!isValidLogoProofofAdrsBack && (
                                          <span className=" has_error help-block">
                                            Address Back is required!&nbsp;
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <div className="bo-file d-flex">
                                          <div className="file-name">
                                            <span className="icon-upload-file _ico">
                                              <span className="path1"></span>
                                              <span className="path2"></span>
                                            </span>
                                            <h5>
                                              <span>{LogoNamePOFBack}</span>
                                            </h5>
                                          </div>
                                          <div
                                            className="ml-auto file-size"
                                            onClick={onCrossImgBack}
                                          >
                                            <span>{LogoSizePOFBack}</span>
                                            <img
                                              className="cross-icon"
                                              src={crossimg}
                                              onClick={onCrossImgBack}
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {!isValidLogoProofofAdrsBack && (
                                        <span className="help-block has_error">
                                          Valid Proof of address is required!
                                        </span>
                                      )}
                                    </div>
                                  )}
                                  <div className="col-xl-6 mt-4">
                                    <div className="form-group submit-field">
                                      <a className="btn btn-large w-100" onClick={submitProofOfAddress}>Next</a>
                                    </div>
                                  </div>
                                  <div className="col-xl-6 mt-4">
                                    <div className="form-group submit-field">
                                      <a
                                        className="btn btn-large w-100"
                                        onClick={() => { sessionStorage.removeItem("user"); window.location.reload(); }}
                                      >
                                        Logout
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                              </Form>
                            </div>
                          </div>
                        </div>

                      </>
                    )
                  }
                  {
                    kycDocLength == 3 && (
                      <div className="col-xl-6 mt-4">
                        <div className="form-group submit-field">
                          <a className="btn btn-large w-100" onClick={submitkyctoissuert}>Submit Kyc</a>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </>)}


          {((currentUser?.user?.IsKycSubmitted || successfulSubmitted) && !boloader) && (
            <div className="bo-on-screens position-relative onbo-content-center minh-row-mbl">
              <div className="col-md-8 col-lg-5">
                <div className="icon-alert">
                  <span className="icon-loading">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h3 className="mb-4">We are reviewing your account</h3>

                <h6>
                  We are reviewing your uploaded documents. This usually take
                  less then 24 hours.
                </h6>
                <div className="form-group submit-field mt-40">
                  <button
                    className="btn btn-large w-100"
                    onClick={() => { sessionStorage.removeItem("user"); window.location.reload(); }}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      />
    </div>
  );
};

export default RegisterIssuerT;
